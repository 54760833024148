




























































































































































































import {
  BButton, BModal, BForm, BFormInput, BFormGroup, BSpinner, BFormTextarea, BFormCheckbox, BFormSelect, BFormDatepicker
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { Component, Prop, Vue } from 'vue-property-decorator';
import { CreateDemandeModificationModel } from '@/api/models/creneaux/creneau';
import { required, min, max } from '@validations'
import { EtatDemandeModification } from '@/api/models/enums/etatDemandeModification';
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";
import { PaginatedList } from "@/api/models/common/paginatedList";
import { DetailsUtilisateur } from "@/api/models/utilisateurs/utilisateur";
import { Entreprise } from '@/api/models/adherents/adherent';
import { Prestation } from '@/api/models/prestations/prestation';
import { Site } from '@/api/models/sites/site';
import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";

@Component({
  components: {
    BButton, 
    BModal, 
    BForm, 
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
    BFormSelect,
    BFormDatepicker,
    SearchableVueSelect
  },
})

export default class CreateDemandeModificationModal extends Vue {
  @Prop({ required: false, default: "Demande de modification" }) title!: string
  demandeModification: CreateDemandeModificationModel = {}

  etatDemandeModification = EtatDemandeModification

  listSalaries = new PaginatedList<DetailsUtilisateur>();
  listEntreprises = new PaginatedList<Entreprise>();
  listPrestations = new PaginatedList<Prestation>();
  listSites: Site[] = []
  listEtats: string[] = []

  loading = false
  creationLoading = false
  show = false

  required = required;
  min = min;
  max = max;

  messagePeriodicity = ""
  isShowingDetailsPeriodicity = false

  periodicityFrequence = Array.from({length: 100}, (_, i) => i + 1)

  async created() {
    await this.getEtats()
  }

  async getEtats() {
    await this.$http.myressif.parametres
      .getByNom("plannings_demande_modification_etat")
      .then((res: any) => {
        if (res.valeur) {
          this.listEtats = JSON.parse(res.valeur);
        }
      }),
      (error: any) => {
        if (
          error?.response?.data?.errors &&
          Object.keys(error.response.data.errors).length
        ) {
          errorAlert.fire({
            text: error.response.data.errors[
              Object.keys(error.response.data.errors)[0]
            ],
          });
        } else {
          errorAlert.fire({
            text: error?.response?.data?.detail ?? error.message,
          });
        }
      };
  }

  async createDemandeModification(event: any) {
    event.preventDefault();
    (this.$refs.formValidation as InstanceType<typeof ValidationObserver>)
      .validate()
      .then((isSuccess) => {
        if (isSuccess) {
          this.$emit('create-demande-modification', this.demandeModification);
        }
      })
  }

  async searchSalaries(params: any) {
    await this.$http.myressif.utilisateurs
      .paginatedList(
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search ?? ""
      )
      .then((response: PaginatedList<DetailsUtilisateur>) => {
        this.listSalaries.items = this.listSalaries!.items.concat(
            response.items.filter(
              (ri) => !this.listSalaries!.items.some((lsi) => lsi.id == ri.id)
            )
          ).map((x: DetailsUtilisateur) => <DetailsUtilisateur>{
          ...x,
          completeLabel: `${x.nom} ${x.prenom}`
        });
        this.listSalaries.hasNextPage = response.hasNextPage
        this.listSalaries.hasPreviousPage = response.hasPreviousPage
        this.listSalaries.pageIndex = response.pageIndex
        this.listSalaries.totalCount = response.totalCount
        this.listSalaries.totalPages = response.totalPages
      });
  }

  async searchEntreprises(params: any) {
    await this.$http.myressif.entreprises
      .paginatedList(
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.orderBy,
        params?.orderByDescending,
        params?.search ?? "",
        params?.pertinentOnly,
        params?.sortStatut
      )
      .then((response: PaginatedList<Entreprise>) => {
        this.listEntreprises.items = this.listEntreprises!.items.concat(
            response.items.filter(
              (ri) => !this.listEntreprises!.items.some((lsi) => lsi.id == ri.id)
            )
          );
        this.listEntreprises.hasNextPage = response.hasNextPage
        this.listEntreprises.hasPreviousPage = response.hasPreviousPage
        this.listEntreprises.pageIndex = response.pageIndex
        this.listEntreprises.totalCount = response.totalCount
        this.listEntreprises.totalPages = response.totalPages
      });
  }

  async searchPrestationsByEntrepriseId(params: any, entrepriseId: string) {
    if (!params || params?.reset) {
      this.listPrestations = new PaginatedList<Prestation>();
    }
    await this.$http.myressif.entreprises
      .getListPrestationsByEntrepriseId(
        entrepriseId,
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search ?? ""
      )
      .then((response: PaginatedList<Prestation>) => {
        this.listPrestations = response;
      });
  }

  async searchSitesByEntrepriseId(params: any, entrepriseId: string) {
    if (!params || params?.reset) {
      this.listSites = [];
    }
    await this.$http.myressif.entreprises
      .getListSitesByEntrepriseId(
        entrepriseId
      )
      .then((response: Site[]) => {
        this.listSites = response;
      }),
      (error: any) => {
        if (
          error?.response?.data?.errors &&
          Object.keys(error.response.data.errors).length
        ) {
          errorAlert.fire({
            text: error.response.data.errors[
              Object.keys(error.response.data.errors)[0]
            ],
          });
        } else {
          errorAlert.fire({
            text: error?.response?.data?.detail ?? error.message,
          });
        }
      };
  }
}
