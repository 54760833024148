








































































































































import {
  BButton, BModal, BForm, BFormInput, BFormGroup, BSpinner, BFormTextarea, BFormCheckbox, BFormSelect, BFormDatepicker, BTab, BTabs
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { Component, Prop, Vue } from 'vue-property-decorator';
import { CreateDuplicationModel } from '@/api/models/creneaux/creneau';
import { required, min, max } from '@validations'
import { FrequencyType } from '@/api/models/enums/frequencyType';
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";
import { PaginatedList } from "@/api/models/common/paginatedList";
import { DetailsUtilisateur } from "@/api/models/utilisateurs/utilisateur";

@Component({
  components: {
    BButton, 
    BModal, 
    BForm, 
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
    BFormSelect,
    BFormDatepicker,
    BTabs,
    BTab,
    SearchableVueSelect
  },
})

export default class CreateDuplicationModal extends Vue {
  @Prop({ required: false, default: "Dupliquer un créneau" }) title!: string
  @Prop({required: true}) creneauId!: string
  duplication: CreateDuplicationModel = {}

  listSalaries = new PaginatedList<DetailsUtilisateur>();


  loading = false
  creationLoading = false
  show = false

  required = required;
  min = min;
  max = max;

  messagePeriodicity = ""
  isShowingDetailsPeriodicity = false

  periodicityFrequence = Array.from({length: 100}, (_, i) => i + 1)
  frequencyType = FrequencyType


  created() {
    this.duplication.creneauId = this.creneauId
  }

  async createDuplication(event: any) {
    event.preventDefault();
    (this.$refs.formValidation as InstanceType<typeof ValidationObserver>)
      .validate()
      .then((isSuccess) => {
        if (isSuccess) {
          this.$emit('create-duplication', this.duplication);
        }
      })
  }

  async searchSalaries(params: any) {
    await this.$http.myressif.utilisateurs
      .paginatedList(
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search ?? ""
      )
      .then((response: PaginatedList<DetailsUtilisateur>) => {
        this.listSalaries.items = this.listSalaries!.items.concat(
            response.items.filter(
              (ri) => !this.listSalaries!.items.some((lsi) => lsi.id == ri.id)
            )
          ).map((x: DetailsUtilisateur) => <DetailsUtilisateur>{
          ...x,
          completeLabel: `${x.nom} ${x.prenom}`
        });
        this.listSalaries.hasNextPage = response.hasNextPage
        this.listSalaries.hasPreviousPage = response.hasPreviousPage
        this.listSalaries.pageIndex = response.pageIndex
        this.listSalaries.totalCount = response.totalCount
        this.listSalaries.totalPages = response.totalPages
      });
  }

  tabChanged(index: any) {
    if (index == 0) {
      this.duplication = {
        creneauId: this.creneauId,
        salariesIds: undefined
      }
    } else if (index == 1) {
      this.duplication = {
        creneauId: this.creneauId,
        dateFin: undefined,
        frequence: undefined,
        frequenceType: undefined,
        occurrences: undefined
      }
    }
  }
}
