




























































































































































import {
  BButton, BModal, BForm, BFormInput, BFormGroup, BSpinner, BFormTextarea, BFormCheckbox, BFormSelect, BFormDatepicker, BTab, BTabs
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DeleteCreneauxPeriodeModel } from '@/api/models/creneaux/creneau';
import { required, min, max } from '@validations'
import { FrequencyType } from '@/api/models/enums/frequencyType';
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";
import { PaginatedList } from "@/api/models/common/paginatedList";
import { DetailsUtilisateur } from "@/api/models/utilisateurs/utilisateur";
import { Entreprise } from '@/api/models/adherents/adherent';
import { TypeCreneau } from '@/api/models/creneaux/typeCreneau';

@Component({
  components: {
    BButton, 
    BModal, 
    BForm, 
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
    BFormSelect,
    BFormDatepicker,
    BTabs,
    BTab,
    SearchableVueSelect
  },
})

export default class DeleteCreneauxPeriodeModal extends Vue {
  @Prop({ required: false, default: "Dupliquer un créneau" }) title!: string
  model: DeleteCreneauxPeriodeModel = {}

  listSalaries = new PaginatedList<DetailsUtilisateur>();
  listEntreprises = new PaginatedList<Entreprise>();
  listTypesCreneau = new PaginatedList<TypeCreneau>();

  loading = false
  creationLoading = false
  show = false

  required = required;

  created() {}

  async deleteCreneauxPeriode(event: any) {
    event.preventDefault();
    (this.$refs.formValidation as InstanceType<typeof ValidationObserver>)
      .validate()
      .then((isSuccess) => {
        if (isSuccess) {
          this.$emit('delete-creneaux-periode', this.model);
        }
      })
  }

  async searchSalaries(params: any) {
    await this.$http.myressif.utilisateurs
      .paginatedList(
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search ?? ""
      )
      .then((response: PaginatedList<DetailsUtilisateur>) => {
        this.listSalaries.items = this.listSalaries!.items.concat(
            response.items.filter(
              (ri) => !this.listSalaries!.items.some((lsi) => lsi.id == ri.id)
            )
          ).map((x: DetailsUtilisateur) => <DetailsUtilisateur>{
          ...x,
          completeLabel: `${x.nom} ${x.prenom}`
        });
        this.listSalaries.hasNextPage = response.hasNextPage
        this.listSalaries.hasPreviousPage = response.hasPreviousPage
        this.listSalaries.pageIndex = response.pageIndex
        this.listSalaries.totalCount = response.totalCount
        this.listSalaries.totalPages = response.totalPages
      });
  }

  async searchEntreprises(params: any) {
    await this.$http.myressif.entreprises
      .paginatedList(
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.orderBy,
        params?.orderByDescending,
        params?.search ?? "",
        params?.pertinentOnly,
        params?.sortStatut
      )
      .then((response: PaginatedList<Entreprise>) => {
        this.listEntreprises.items = this.listEntreprises!.items.concat(
            response.items.filter(
              (ri) => !this.listEntreprises!.items.some((lsi) => lsi.id == ri.id)
            )
          );
        this.listEntreprises.hasNextPage = response.hasNextPage
        this.listEntreprises.hasPreviousPage = response.hasPreviousPage
        this.listEntreprises.pageIndex = response.pageIndex
        this.listEntreprises.totalCount = response.totalCount
        this.listEntreprises.totalPages = response.totalPages
      });
  }

  async searchTypesCreneau(params: any) {
    if (!params || params?.reset) {
      this.listTypesCreneau = new PaginatedList<TypeCreneau>();
    }
    await this.$http.myressif.typesCreneau
      .paginatedList(
        params?.search ?? "",
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
      )
      .then((response: PaginatedList<TypeCreneau>) => {
        this.listTypesCreneau = response;
      });
  }
}
