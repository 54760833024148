var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$can('PLANNING_ADMIN:admin') || _vm.$can('PLANNING_SUPERADMIN:admin'))?_c('validation-observer',{ref:"formValidation",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-modal',{attrs:{"id":"modal-create-duplication-salarie","cancel-variant":"outline-secondary","ok-title":"Dupliquer un créneau","cancel-title":"Fermer","centered":"","title":_vm.title,"ok-disabled":invalid},on:{"ok":_vm.createDuplication},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('div',{staticClass:"w-100"},[(!_vm.creationLoading)?_c('div',[_c('b-button',{staticClass:"float-right ml-1",attrs:{"variant":"primary"},on:{"click":_vm.createDuplication}},[_vm._v(" Dupliquer ")]),_c('b-button',{staticClass:"float-right",attrs:{"variant":"outline-secondary"},on:{"click":function($event){_vm.show=false}}},[_vm._v(" Fermer ")])],1):_c('div',{staticClass:"text-center"},[_c('i',[_vm._v("Création des créneaux...")]),_c('br'),_c('b-spinner',{attrs:{"label":"Chargement...","variant":"primary"}})],1)])]},proxy:true}],null,true),model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('validation-provider',{attrs:{"name":"Date de début de la duplication","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('label',{attrs:{"for":"dateDebutDuplication"}},[_vm._v(" Période du "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('b-form-datepicker',{attrs:{"id":"dateDebutDuplication","placeholder":"Pas de date choisie"},model:{value:(_vm.duplication.dateDebut),callback:function ($$v) {_vm.$set(_vm.duplication, "dateDebut", $$v)},expression:"duplication.dateDebut"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Date de fin de la duplication","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('label',{attrs:{"for":"dateFinDuplication"}},[_vm._v(" Fin le "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('b-form-datepicker',{attrs:{"id":"dateFinDuplication","placeholder":"Pas de date choisie"},model:{value:(_vm.duplication.dateFin),callback:function ($$v) {_vm.$set(_vm.duplication, "dateFin", $$v)},expression:"duplication.dateFin"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Salarié(s)","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('label',{attrs:{"for":"salaries"}},[_vm._v(" Salarié(s) "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('searchable-vue-select',{attrs:{"id":"salaries","multiple":"","label":"completeLabel","placeholder":"Choisissez un ou plusieurs salarié(s)","options":_vm.listSalaries.items,"hasNextPage":_vm.listSalaries.items.length <
              _vm.listSalaries.totalCount,"loadOnCreate":true,"reduce":function (option) { return option.id; }},on:{"load-next-page":function (params) { return _vm.searchSalaries(params); }},model:{value:(_vm.duplication.salariesIds),callback:function ($$v) {_vm.$set(_vm.duplication, "salariesIds", $$v)},expression:"duplication.salariesIds"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],staticClass:"text-center"},[_c('b-spinner',{attrs:{"label":"Chargement...","variant":"primary"}})],1)],1)]}}],null,false,2013135982)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }