






















































































































































import {
  BButton, BModal, BForm, BFormInput, BFormGroup, BSpinner, BFormTextarea, BFormCheckbox, BFormSelect, BFormDatepicker
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { Component, Prop, Vue } from 'vue-property-decorator';
import { EditPeriodiciteModel } from '@/api/models/creneaux/creneau';
import { required, min, max } from '@validations'
import { FrequencyType } from '@/api/models/enums/frequencyType';

@Component({
  components: {
    BButton, 
    BModal, 
    BForm, 
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
    BFormSelect,
    BFormDatepicker
  },
})

export default class EditPeriodiciteModal extends Vue {
  @Prop({ required: false, default: "Modifier la périodicité" }) title!: string
  @Prop({required: true}) periodicite!: EditPeriodiciteModel

  loading = false
  creationLoading = false
  show = false

  required = required;
  min = min;
  max = max;

  messagePeriodicity = ""
  isShowingDetailsPeriodicity = false

  periodicityFrequence = Array.from({length: 100}, (_, i) => i + 1)
  frequencyType = FrequencyType


  created() {
    this.getIsShowingDetailsPeriodicity()
  }

  async editPeriodicite(event: any) {
    event.preventDefault();
    (this.$refs.formValidation as InstanceType<typeof ValidationObserver>)
      .validate()
      .then((isSuccess) => {
        if (isSuccess) {
          this.$emit('edit-periodicite', this.periodicite);
        }
      })
  }

  getEnumKeyByEnumValue(myEnum: any, enumValue: number | string): string {
    let keys = Object.keys(myEnum).filter((x) => myEnum[x] == enumValue);
    return keys.length > 0 ? keys[0] : '';
  }

  getIsShowingDetailsPeriodicity() {
    if (!this.periodicite || !this.periodicite.frequence || !this.periodicite.frequenceType) {
      this.isShowingDetailsPeriodicity = false
      return
    }

    if (this.periodicite.frequence > 1 && this.periodicite.frequenceType == this.getEnumKeyByEnumValue(FrequencyType, this.frequencyType.Daily)) {
      this.isShowingDetailsPeriodicity = false
      this.messagePeriodicity = "Tous les " + this.periodicite.frequence + " jours jusqu'au " + new Date(this.periodicite.dateFin).toLocaleDateString("fr-FR")
    }
    else if (this.periodicite.frequence == 1 && this.periodicite.frequenceType == this.getEnumKeyByEnumValue(FrequencyType, this.frequencyType.Daily)) {
      this.messagePeriodicity = "Tous les jours jusqu'au " + new Date(this.periodicite.dateFin).toLocaleDateString("fr-FR")
      this.isShowingDetailsPeriodicity = true
    }
    else if(this.periodicite.frequenceType == this.getEnumKeyByEnumValue(FrequencyType, this.frequencyType.Weekly)) {
      this.isShowingDetailsPeriodicity = true
      if (this.periodicite.frequence == 1) 
        this.messagePeriodicity = "Toutes les semaines"
      else
        this.messagePeriodicity = "Toutes les " + this.periodicite.frequence + " semaines"

      if (this.periodicite.lun)
        this.messagePeriodicity += ", le lundi"

      if (this.periodicite.mar)
        this.messagePeriodicity += ", le mardi"

      if (this.periodicite.mer)
        this.messagePeriodicity += ", le mercredi"

      if (this.periodicite.jeu)
        this.messagePeriodicity += ", le jeudi"

      if (this.periodicite.ven)
        this.messagePeriodicity += ", le vendredi"

      this.messagePeriodicity += " jusqu'au " + new Date(this.periodicite.dateFin).toLocaleDateString("fr-FR")
    }
    else {
      this.isShowingDetailsPeriodicity = true
    }
  }
}
