














































































































































































































































































































































































































































































































import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import FullCalendar from '@fullcalendar/vue';
import {
  BButton,
  BCard,
  BCol,
  BDropdown,
  BFormSelect,
  BDropdownItem,
  BFormInput,
  BFormCheckbox,
  BPagination,
  BRow,
  BTable,
  BBadge,
  BAlert,
  BFormGroup,
  BFormTag, 
  BSidebar,
  BFormTextarea,
  BFormInvalidFeedback,
  BFormRadio,
  BFormDatepicker,
  BForm,
  BFormTimepicker,
  VBTooltip
} from "bootstrap-vue";
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";
import { PaginatedList } from "@/api/models/common/paginatedList";
import { DetailsUtilisateur, ShortUtilisateur } from "@/api/models/utilisateurs/utilisateur";
import { ShortEntreprise } from "@/api/models/adherents/adherent";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { Prestation } from "@/api/models/prestations/prestation";
import { Site } from "@/api/models/sites/site";
import { TypeCreneau } from "@/api/models/creneaux/typeCreneau";
import EditPeriodiciteModal from './EditPeriodiciteModal.vue';
import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";
import { EditPeriodiciteModel } from "@/api/models/creneaux/creneau";
import { FrequencyType } from '@/api/models/enums/frequencyType'
import { SousTypeCreneau } from "@/api/models/typesCreneau/sousTypeCreneau";


@Component({
  components: {
    FullCalendar,
    BButton,
    BCard,
    BCol,
    BDropdown,
    BFormSelect,
    BDropdownItem,
    BFormInput,
    BFormCheckbox,
    BPagination,
    BRow,
    BTable,
    BBadge,
    BAlert,
    BFormGroup,
    SearchableVueSelect,
    BFormTag,
    BSidebar,
    ValidationObserver,
    ValidationProvider,
    BFormTextarea,
    BFormInvalidFeedback,
    BFormRadio,
    BFormDatepicker,
    BForm,
    BFormTimepicker,
    EditPeriodiciteModal
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
})
export default class AddCreneauModal extends Vue {
  @Prop({ required: true }) event!: any;
  @Prop({ required: false }) eventId?: string;
  @Prop({ required: true }) isEventHandlerSidebarActive!: boolean;

  messagePeriodicity = ""
  isShowingDetailsPeriodicity = false
  refreshIndex = 0

  listSalaries = new PaginatedList<ShortUtilisateur>();
  listSalariesAlerter = new PaginatedList<ShortUtilisateur>();
  listEntreprises = new PaginatedList<ShortEntreprise>();
  listPrestations = new PaginatedList<Prestation>();
  listTypesCreneau = new PaginatedList<TypeCreneau>();
  listSousTypesCreneau = new PaginatedList<SousTypeCreneau>();
  listSites: Site[] = []

  type= [
    { text: "Absence", value: 0 },
    { text: "Absence prévisionnelle", value: 1 },
    { text: "Vacation - Physique", value: 2 },
    { text: "Vacation - Téléphonique", value: 3 }
  ]

  eventLocal: any = {
    periodicite: {
      lundi: false,
      mardi: false,
      mercredi: false,
      jeudi: false,
      vendredi: false,
      type: null,
      frequence: null,
      dateFin: null
    }
  }
  resetEventLocal() {
    this.eventLocal = JSON.parse(JSON.stringify(this.event))
  }

  periodicityFrequence = Array.from({length: 100}, (_, i) => i + 1)
  frequencyType = FrequencyType

  get getCanEditOrCreate() {
    return this.$can('PLANNING_ADMIN:admin') || this.$can('PLANNING_SUPERADMIN:admin')
  }

  get getTypeCreneauLibelle() {
    var libelle = this.listTypesCreneau.items.find(tc => tc.id == this.eventLocal!.typeCreneauId)?.libelle

    const premiereLettre = libelle!.charAt(0).toLowerCase();
    
    // Déterminer si la première lettre est une voyelle
    const estVoyelle = ['a', 'e', 'i', 'o', 'u'].includes(premiereLettre);

    // Retourner "d'" si c'est une voyelle, sinon retourner "de"
    return "Type " + (estVoyelle ? "d'" : "de ") + libelle?.toLowerCase();
  }


  async created() {
    this.eventLocal = JSON.parse(JSON.stringify(this.event));
    if (!this.eventLocal.periodicite) {
      this.eventLocal.periodicite = {
        lundi: false,
        mardi: false,
        mercredi: false,
        jeudi: false,
        vendredi: false,
        frequence: null,
        type: null,
        dateFin: null
      }
    }

    if (this.eventLocal.id && this.eventLocal.id != null) {
      await this.$http.myressif.creneaux
        .getById(this.eventLocal.id)
        .then((response: any) => {
          this.eventLocal = response

          var start = new Date(this.eventLocal.dateDebut)
          this.eventLocal.start = start.getHours() + ":" + start.getMinutes() + ":" + start.getSeconds()

          var end = new Date(this.eventLocal.dateFin)
          this.eventLocal.end = end.getHours() + ":" + end.getMinutes() + ":" + end.getSeconds()

          this.eventLocal.dateCreneau = start
          this.getIsShowingDetailsPeriodicity()

          this.eventLocal.salaries.forEach((salarie: ShortUtilisateur) => {
            this.listSalaries.items.push({
              id: salarie.id,
              nom: salarie.nom,
              prenom: salarie.prenom,
              completeLabel: salarie.nom + " " + salarie.prenom
            })
          });

          this.eventLocal.salariesAlerter.forEach((salarie: ShortUtilisateur) => {
            this.listSalariesAlerter.items.push({
              id: salarie.id,
              completeLabel: salarie.nom + " " + salarie.prenom,
              nom: salarie.nom,
              prenom: salarie.prenom
            })
          });
        }
      );

      await this.searchSousTypesCreneau(null, this.eventLocal.typeCreneauId)
    } else {
      this.eventLocal.salaries.forEach((salarie: ShortUtilisateur) => {
        this.listSalaries.items.push({
          id: salarie.id,
          nom: salarie.nom,
          prenom: salarie.prenom,
          completeLabel: salarie.completeLabel
        })
      });
    }

    this.listEntreprises.items.push({
      id: this.eventLocal.entrepriseId,
      libelle: this.eventLocal.entrepriseLibelle
    })
    this.listEntreprises.totalCount = 1

    await this.searchSalaries(null);
    await this.searchSalariesAlerter(null);
  }

  getEnumKeyByEnumValue(myEnum: any, enumValue: number | string): string {
    let keys = Object.keys(myEnum).filter((x) => myEnum[x] == enumValue);
    return keys.length > 0 ? keys[0] : '';
  }

  getIsShowingDetailsPeriodicity() {
    if (!this.eventLocal.periodicite || !this.eventLocal.periodicite.frequence || !this.eventLocal.periodicite.frequenceType) {
      this.isShowingDetailsPeriodicity = false
      return
    }

    if (this.eventLocal.periodicite.frequence > 1 && this.eventLocal.periodicite.frequenceType == this.getEnumKeyByEnumValue(FrequencyType, this.frequencyType.Daily)) {
      this.isShowingDetailsPeriodicity = false
      this.messagePeriodicity = "Tous les " + this.eventLocal.periodicite.frequence + " jours jusqu'au " + new Date(this.eventLocal.periodicite.dateFin).toLocaleDateString("fr-FR")
    }
    else if (this.eventLocal.periodicite.frequence == 1 && this.eventLocal.periodicite.frequenceType == this.getEnumKeyByEnumValue(FrequencyType, this.frequencyType.Daily)) {
      this.messagePeriodicity = "Tous les jours jusqu'au " + new Date(this.eventLocal.periodicite.dateFin).toLocaleDateString("fr-FR")
      this.isShowingDetailsPeriodicity = true
    }
    else if(this.eventLocal.periodicite.frequenceType == this.getEnumKeyByEnumValue(FrequencyType, this.frequencyType.Weekly)) {
      this.isShowingDetailsPeriodicity = true
      if (this.eventLocal.periodicite.frequence == 1) 
        this.messagePeriodicity = "Toutes les semaines"
      else
        this.messagePeriodicity = "Toutes les " + this.eventLocal.periodicite.frequence + " semaines"

      if (this.eventLocal.periodicite.lun)
        this.messagePeriodicity += ", le lundi"

      if (this.eventLocal.periodicite.mar)
        this.messagePeriodicity += ", le mardi"

      if (this.eventLocal.periodicite.mer)
        this.messagePeriodicity += ", le mercredi"

      if (this.eventLocal.periodicite.jeu)
        this.messagePeriodicity += ", le jeudi"

      if (this.eventLocal.periodicite.ven)
        this.messagePeriodicity += ", le vendredi"

      this.messagePeriodicity += " jusqu'au " + new Date(this.eventLocal.periodicite.dateFin).toLocaleDateString("fr-FR")
    }
    else {
      this.isShowingDetailsPeriodicity = true
    }
    this.refreshIndex++
  }

  async editPeriodicite(periodicite: EditPeriodiciteModel) {
    (this.$refs.editPeriodicite as EditPeriodiciteModal).creationLoading = true

    await this.$http.myressif.creneaux
      .updatePeriodicite(this.eventLocal.id, periodicite.id, periodicite)
      .then(
        async (response: any) => {
          (this.$refs.editPeriodicite as EditPeriodiciteModal).creationLoading = false
          successAlert.fire({
            timer: 3500,
            title: "Création de créneaux",
            text: "Créneaux créés avec succès",
          });

          this.$emit('refetch-events');
          (this.$refs.editPeriodicite as EditPeriodiciteModal).show = false
        },
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
        }
      )
  }

  onSubmit() {
    const eventData = JSON.parse(JSON.stringify(this.eventLocal))

    if (this.eventLocal.id) this.$emit('update-event', eventData)
    else this.$emit('add-event', eventData)

    this.$emit('update:is-event-handler-sidebar-active', false)
  }


  async searchSalaries(params: any) {
    if (!params || params?.reset) {
      this.listSalaries.items = this.listSalaries.items?.filter(e => this.eventLocal.salariesIds.includes(e.id));
    }

    await this.$http.myressif.utilisateurs
      .paginatedList(
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search ?? ""
      )
      .then((response: PaginatedList<DetailsUtilisateur>) => {
        this.listSalaries.items = this.listSalaries!.items.concat(
            response.items.filter(
              (ri) => !this.listSalaries!.items.some((lsi) => lsi.id == ri.id)
            )
          ).map((x: ShortUtilisateur) => <DetailsUtilisateur>{
          ...x,
          completeLabel: x.completeLabel ?? `${x.nom} ${x.prenom}`
        });
        this.listSalaries.hasNextPage = response.hasNextPage
        this.listSalaries.hasPreviousPage = response.hasPreviousPage
        this.listSalaries.pageIndex = response.pageIndex
        this.listSalaries.totalCount = response.totalCount
        this.listSalaries.totalPages = response.totalPages
      });
  }

  async searchSalariesAlerter(params: any) {
    if (!params || params?.reset) {
      this.listSalariesAlerter.items = this.listSalariesAlerter.items?.filter(e => this.eventLocal.salariesAlerterIds.includes(e.id));
    }

    await this.$http.myressif.utilisateurs
      .paginatedList(
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search ?? ""
      )
      .then((response: PaginatedList<DetailsUtilisateur>) => {
        this.listSalariesAlerter.items = this.listSalariesAlerter!.items.concat(
            response.items.filter(
              (ri) => !this.listSalariesAlerter!.items.some((lsi) => lsi.id == ri.id)
            )
          ).map((x: ShortUtilisateur) => <DetailsUtilisateur>{
          ...x,
          completeLabel: `${x.nom} ${x.prenom}`
        });
        this.listSalariesAlerter.hasNextPage = response.hasNextPage
        this.listSalariesAlerter.hasPreviousPage = response.hasPreviousPage
        this.listSalariesAlerter.pageIndex = response.pageIndex
        this.listSalariesAlerter.totalCount = response.totalCount
        this.listSalariesAlerter.totalPages = response.totalPages
      });
  }

  async searchEntreprises(params: any) {
    if (!params || params?.reset) {
      this.listEntreprises.totalCount = 1
      this.listEntreprises.items = this.listEntreprises.items?.filter(e => e.id == this.eventLocal.entrepriseId);
    }

    await this.$http.myressif.entreprises
      .paginatedList(
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.orderBy,
        params?.orderByDescending,
        params?.search ?? "",
        params?.pertinentOnly,
        params?.sortStatut
      )
      .then((response: PaginatedList<ShortEntreprise>) => {
        this.listEntreprises.items = this.listEntreprises!.items.concat(
            response.items.filter(
              (ri) => !this.listEntreprises!.items.some((lsi) => lsi.id == ri.id)
            )
          );
        this.listEntreprises.hasNextPage = response.hasNextPage
        this.listEntreprises.hasPreviousPage = response.hasPreviousPage
        this.listEntreprises.pageIndex = response.pageIndex
        this.listEntreprises.totalCount = response.totalCount
        this.listEntreprises.totalPages = response.totalPages
      });
  }

  async searchPrestationsByEntrepriseId(params: any, entrepriseId: string) {
    if (!params || params?.reset) {
      this.listPrestations = new PaginatedList<Prestation>();
    }
    await this.$http.myressif.entreprises
      .getListPrestationsByEntrepriseId(
        entrepriseId,
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search ?? ""
      )
      .then((response: PaginatedList<Prestation>) => {
        this.listPrestations.items = this.listPrestations!.items.concat(
            response.items.filter(
              (ri) => !this.listPrestations!.items.some((lsi) => lsi.id == ri.id)
            )
          );
        this.listPrestations.totalCount = response.totalCount
      });
  }

  async searchTypesCreneau(params: any) {
    if (!params || params?.reset) {
      this.listTypesCreneau = new PaginatedList<TypeCreneau>();
    }
    await this.$http.myressif.typesCreneau
      .paginatedList(
        params?.search ?? "",
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
      )
      .then((response: PaginatedList<TypeCreneau>) => {
        this.listTypesCreneau.items = this.listTypesCreneau!.items.concat(
            response.items.filter(
              (ri) => !this.listTypesCreneau!.items.some((lsi) => lsi.id == ri.id)
            )
          );
        this.listTypesCreneau.totalCount = response.totalCount
      });
  }

  async searchSousTypesCreneau(params: any, typeCreneauId: string) {
    if (!params || params?.reset) {
      this.listSousTypesCreneau = new PaginatedList<SousTypeCreneau>();
    }

    await this.$http.myressif.typesCreneau
      .getListSousTypesCreneauByTypeCreneauId(
        typeCreneauId,
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search ?? ""
      )
      .then((response: PaginatedList<SousTypeCreneau>) => {
        this.listSousTypesCreneau.items = this.listSousTypesCreneau!.items.concat(
            response.items.filter(
              (ri) => !this.listSousTypesCreneau!.items.some((lsi) => lsi.id == ri.id)
            )
          );
        this.listSousTypesCreneau.totalCount = response.totalCount
      });
  }

  async loadListSites(params: any) {
    if (!params || params?.reset) {
      this.listSites = [];
    }
    await this.$http.myressif.entreprises
      .getListSitesByEntrepriseId(
        this.eventLocal.entrepriseId
      )
      .then((response: Site[]) => {
        this.listSites = response;
      }),
      (error: any) => {
        if (
          error?.response?.data?.errors &&
          Object.keys(error.response.data.errors).length
        ) {
          errorAlert.fire({
            text: error.response.data.errors[
              Object.keys(error.response.data.errors)[0]
            ],
          });
        } else {
          errorAlert.fire({
            text: error?.response?.data?.detail ?? error.message,
          });
        }
      };
  }

}
